import React from 'react';
import { Avatar } from '@oneclass/onedesign';
import { StyledHeader } from './Header.style';
import { useAuthState } from 'providers/AuthProvider';


export const Header = ({ className, children }) => {
  const state = useAuthState();
  const { userProfile } = state.context;
  return (
    <StyledHeader className={className} >
      <div className="av">鈴</div>
      <Avatar src={userProfile?.thumbnailUrl} size="48px"/>
      {children}
    </StyledHeader>
  );
};