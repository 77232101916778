import styled from 'styled-components';
import { TagProps } from './Tag';

export const StyledTag = styled.div.attrs(props => ({
  className: props.className
}))<TagProps>`

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    padding: 4px 8px;
    font-size:12px;
    min-width: 40px;
    min-height: 24px;

    color: ${(props) => props.active ? props.theme.colors.text.white : props.theme.colors.text.text_10};
    background:${(props) => props.active ? props.theme.colors.brand.brand02 : props.theme.colors.ui.ui02} ;
    border-radius: 4px;
    & >span {
      display:flex;
      margin-left:4px;
      cursor: pointer;
    }
`;