import styled from 'styled-components';
import { LayoutProps, variant, layout } from 'styled-system';
import { VariantEnum } from './types';

export type SkeletonProps = {
    className?: string;
    variant: typeof VariantEnum[keyof typeof VariantEnum]
} & LayoutProps;

export const Skeleton = styled.div.attrs({
  'data-testid': 'Skeleton'
})<SkeletonProps>`
  min-height: 20px;
  animation: skeleton-loading 1.4s ease infinite;
    background: linear-gradient(
90deg
,hsla(0,0%,74.5%,.2) 25%,hsla(0,0%,50.6%,.24) 37%,hsla(0,0%,74.5%,.2) 63%);
background-size: 400% 100%;

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

${
  variant({
    prop: 'variant',
    variants: {
      [VariantEnum.Text]: {
        width: '65%',
        height: '32px',
        borderRadius: '8px',
      },
      [VariantEnum.Image]: {
        width: '80px',
        height: '80px',
        borderRadius: '20px',
      },
      [VariantEnum.Block]: {
        width: '100%',
        height: '80px',
        borderRadius: '20px',
      },
    },
  })
}

${layout}
`;
