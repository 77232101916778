import * as colors from './colorBase';

export default {
  scale: colors.$scale,

  // Pure functional
  brand: {
    brand01: colors.$brand01_10,
    brand02: colors.$brand02_10,
    brand03: colors.$brand03_10,
  },
  support: {
    info: '#4E4E5A',
    success: '#4CAF50',
    error: '#F44336',
    warning: '#FF9800',
    star: '#F9C74F',
  },
  state: {
    hover: {
      brand01: colors.$brand01_09,
      brand02: colors.$brand02_09,
      brand03: colors.$brand03_09,
      info: '#61616B',
      success: '#5EB762',
      error: '#F6564B',
      warning: '#FFA31A',
      star: '#FACD61',
      row: '#F5F5F5'
    }
  },
  text: {
    white: colors.$white,
    text_01: '#FAFAFC',
    text_02: '#F2F4F8',
    text_03: '#E4E7EC',
    text_04: '#D5D7DE',
    text_05: '#A1A4B1',
    text_06: '#8B90A0',
    text_07: '#454B5C',
    text_08: '#3A4052',
    text_09: '#2F3649',
    text_10: '#242C3F',
    text_11: '#0E172C',
    black: colors.$black,
  },
  ui: {
    white: colors.$white,
    ui_01: '#FAFAFC',
    ui_02: '#F2F4F8',
    ui_03: '#E4E7EC',
    ui_04: '#D5D7DE',
    ui_05: '#A1A4B1',
    ui_06: '#8B90A0',
    ui_07: '#454B5C',
    ui_08: '#3A4052',
    ui_09: '#2F3649',
    ui_10: '#242C3F',
    ui_11: '#0E172C',
    black: colors.$black,
  },
};
