import React, { useState } from 'react';
import { useAuthState } from 'providers/AuthProvider';
import { AuthenticationMachineState } from 'machines/authMachine';
import RenderRoutes from 'routes/RenderRoutes';
import { Skeleton } from '@oneclass/onedesign';
import { Header, SessionGroup } from 'containers';
import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import {
  StyledDashBoardLayout
} from './DashBoardLayout.style';


const GREETINGS = {
  MORNING: '早安',
  AFTERNOON: '午安',
  NIGHT: '晚安'
};

const getTimeState = () => {
  const nowHour = new Date().getHours();
  if (nowHour >= 0 && nowHour <= 12) {
    return GREETINGS.MORNING;
  } else if (nowHour > 12 && nowHour <= 18) {
    return GREETINGS.AFTERNOON;
  } else if (nowHour > 18 && nowHour <= 24) {
    return GREETINGS.NIGHT;
  }
};

export const DashBoardLayout = ({ routes }) => {
  const authState = useAuthState();
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, [1000]);

  return (
    <StyledDashBoardLayout>
      <div className="leftSidebar">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="全國最大線上家教中心"/>
          </Link>
        </div>
        {isLoading ? <Skeleton className="dashSkeleton" width="200px"/> : <div className="greetings">{getTimeState()} !</div>}
        <div className="main">
          <div className="subTitle">
            {
              isLoading ?
              <Skeleton width="200px"/> :
              <>
                <span>我的今日課程</span>
                <Link className="readMore" to="/personalTimetable">查看全部</Link>
              </>
            }
          </div>
          <SessionGroup/>
        </div>
      </div>
      <Header className="header"/>
      <div className="main">
        {authState.value === AuthenticationMachineState.LOGGED_IN && <RenderRoutes routes={routes} />}
      </div>
    </StyledDashBoardLayout>
  );
};