import styled from 'styled-components';

export const StyledSessionGroup = styled.div.attrs(props => ({
  className: props.className
}))`  
  flex: 1;
  max-height: 432px;
  overflow-x: hidden;

  & > div + div {
    margin-top: 24px;
  }
`;