import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from 'providers/AuthProvider';
import ThemeProvider from 'providers/ThemeProvider';

import 'antd/dist/antd.css';
import './index.css';


const root = document.getElementById('root');
const Main = () => (
  <BrowserRouter>
    <AuthProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>
);

ReactDOM.render(Main(), root);

reportWebVitals();
