import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getGroupSessions } from 'api/groups';
import { getNotifications } from 'api/notifications';
import { useMachine } from '@xstate/react';
import fetchDataMachine, { FETCH_DATA_EVENT } from 'machines/fetchDataMachine';
import { Icon, Box, Tag, Skeleton } from '@oneclass/onedesign';
import { GroupTimeTable, GroupNewsTable } from 'containers';
import { ROLE, EDUCATION_NAME, GRADES } from 'constants/index';
import { getTime } from 'date-fns';
import { useNotificationsFactory } from 'utils/customHooks';
import { StyledGroupInformation } from './GroupInformation.style';

export const GroupInformation = ({ targetGroup }) => {
  const history = useHistory();
  const [currentDateTimestamp, setCurrentDateTimestamp] = useState(getTime(new Date()));
  const { createNotification } = useNotificationsFactory();
  const goToGroupPage = () => {
    history.push();
  };

  const [groupSessionsContext, groupSessionsSend] = useMachine(fetchDataMachine, {
    services: {
      fetchData: (_context, event) => {
        const payload = {
          startAt: event.startAt,
          endAt: event.endAt
        };
        return getGroupSessions(event.id, payload);
      },
    },
  });

  const groupSessionData = groupSessionsContext.context.data;
  const groupsSessionLoading = groupSessionsContext.context.isLoading;

  const [notificationsGroupsContext, notificationsGroupsSend] = useMachine(fetchDataMachine, {
    services: {
      fetchData: (_context, event) => {
        const payload = {
          groupId: event.id,
        };
        return getNotifications(payload);
      },
    },
  });

  const notificationsData = notificationsGroupsContext.context.data || [];
  const notificationsLoading = notificationsGroupsContext.context.isLoading;

  const nextNotificationsData = useMemo(() => {
    return notificationsData.map(item => {
      const { info, eventCategory } = item;
      const { message } = createNotification(eventCategory, info);
      return {
        ...item,
        message
      };
    });
  }, [notificationsData]);

  const onDateTimeChange = timestamp => {
    setCurrentDateTimestamp(timestamp);
  };

  const getGroupSession = () => {
    const startAt = new Date(new Date(currentDateTimestamp).toLocaleDateString()).getTime();
    const endAt = startAt + (24 * 60 * 60 * 1000) - 1;
    const payload = {
      startAt,
      endAt,
      id: targetGroup.id
    };
    groupSessionsSend(FETCH_DATA_EVENT.FETCH, payload);
  };

  useEffect(() => {
    if (!targetGroup) return;
    getGroupSession();
  }, [targetGroup, currentDateTimestamp]);

  useEffect(() => {
    if (!targetGroup) return;
    notificationsGroupsSend(FETCH_DATA_EVENT.FETCH, {
      id: targetGroup.id
    });
  }, [targetGroup]);

  return (
    <StyledGroupInformation data-testid="GroupInformation">
      <div className="header">
        <Box className="group">
          <Box className="groupName" display="flex" justifyContent="space-between">
            {targetGroup?.name || <Skeleton width="331px"/>}
            <div className="controls">
              <Icon name="ArrowRightOutline" size="xs" onClick={goToGroupPage}/>
            </div>
          </Box>
          <Box className="groupInfo" display="flex" alignItems="center">
            {
              targetGroup ?
              <>
                <Icon name="SchoolSolid" className="icon"/>
                <span className="orgName">{targetGroup?.organizationName || targetGroup?.schoolName}</span>
                {targetGroup?.organizationRole && <Tag active className="groupTag">{ROLE[targetGroup?.organizationRole]}</Tag>}
                <Tag className="groupTag">{targetGroup?.year}</Tag>
                {targetGroup?.educationName && <Tag className="groupTag">{EDUCATION_NAME[targetGroup?.educationName]}</Tag>}
                {targetGroup?.grades && targetGroup?.grades.map(grade => {
                  return (
                    <Tag className="groupTag" key={grade}>{GRADES[grade]}</Tag>
                  );
                })}
              </> :
              <Skeleton width="432px"/>
            }
          </Box>
        </Box>
      </div>
      <div className="content">
        {(!targetGroup || groupsSessionLoading || notificationsLoading) ?
          <>
            <Skeleton width="50%" height="432px" variant="block"/>
            <Skeleton width="50%" height="432px" variant="block"/>
          </> :
          <>
            <Box className="box" width="50%">
              <GroupTimeTable onChange={onDateTimeChange} data={groupSessionData?.sessions || []}/>
            </Box>
            <Box className="box" width="50%">
              <GroupNewsTable data={nextNotificationsData || []}/>
            </Box>
          </>}
      </div>
    </StyledGroupInformation>
  );
};