import React from 'react';
import { ThemeProvider as UiThemeProvider } from '@oneclass/ui-components';
import { Normalize } from 'styled-normalize';
import theme from './theme';

export default function ThemeProvider({ children }) {
  return (
    <UiThemeProvider theme={theme}>
      <Normalize />
      {children}
    </UiThemeProvider>
  );
}