/**
 * variable & Object key 大寫
 */

export const ROLE = {
  customer: '學生',
  staff: '老師',
};

export const EDUCATION_NAME = {
  E: '國小',
  J: '國中',
  H: '高中',
  other: '其他'
};

export const GRADES = {
  1: '一年級',
  2: '二年級',
  3: '三年級',
  4: '四年級',
  5: '五年級',
  6: '六年級',
};

export const NOTIFICATION_TYPE = {
  SESSION: 'session',
  MISSION: 'mission'
};