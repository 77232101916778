import React, { useState } from 'react';
import { StyledAvatar } from './Avatar.style';


export type AvatarProps = {
  className?: string;
  src?: string;
  size?: string;
  alt?: string;
};

export const Avatar = (props: AvatarProps) => {
  const { className, src, size, alt } = props;
  const [isError, setIsError] = useState(false);

  return (
    <StyledAvatar className={className} data-testid="Avatar" size={size}>
      {
        src && !isError ? <img src={src} onError={() => {setIsError(true);}} /> : alt
      }
    </StyledAvatar>
  );
};