import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Box } from '@oneclass/onedesign';
import { TimeTable } from '@oneclass/ui-components';
import { getTime, addDays, format } from 'date-fns';
import { StyledGroupTimeTable } from './GroupTimeTable.style';

export const GroupTimeTable = ({ className, onChange = () => {}, data }) => {
  const history = useHistory();
  const [currentDateTimestamp, setCurrentDateTimestamp] = useState(getTime(new Date()));

  const changeHandler = day => () => {
    const timestamp = getTime(addDays(currentDateTimestamp, day));
    setCurrentDateTimestamp(timestamp);
    onChange(timestamp);
  };

  const month = format(currentDateTimestamp, 'MM');
  const day = format(currentDateTimestamp, 'dd');

  const goToGroup = () => {
    history.push('/personalTimetable');
  };

  return (
    <StyledGroupTimeTable className={className} data-testid="GroupTimeTable">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box className="dateSelect">
          <span>{month}月{day}日</span>
          <Box display="flex" alignItems="center" marginLeft={'14px'}>
            <Box className="controlsButton" onClick={changeHandler(-1)}>
              <Icon name="ArrowLeftOutline" size="xs"/>
            </Box>
            <Box className="controlsButton" onClick={changeHandler(1)} ml={2}>
              <Icon name="ArrowRightOutline" size="xs" />
            </Box>
          </Box>
        </Box>
        <Box className="checkAll" onClick={goToGroup}>查看全部</Box>
      </Box>
      <TimeTable>
        {
          data.map((item, index) => (
            <TimeTable.Row
              key={index}
              startTime={item.startAt}
              endTime={item.endAt}
            >
              <span>{item.subjects[0].name}</span>
              <span>{item.groupName}</span>
            </TimeTable.Row>
          ))
        }
      </TimeTable>
    </StyledGroupTimeTable>
  );
};