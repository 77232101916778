import { assign, createMachine } from 'xstate';
import { logoutNaniOneClass, checkNaniLinkLogin } from 'services/oneClub';
import { getMe } from 'api/user';

export const AuthenticationMachineState = {
  CHECKING_IF_LOGGED_IN: 'CHECKING_IF_LOGGED_IN',
  CHECK_IF_LOGGED_IN: 'CHECK_IF_LOGGED_IN',
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
};

export const AuthenticationMachineEvent = {
  REPORT_IS_LOGGED_IN: 'REPORT_IS_LOGGED_IN',
  REPORT_IS_LOGGED_OUT: 'REPORT_IS_LOGGED_OUT',
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
};

const authenticationMachine = createMachine(
  {
    id: 'authentication',
    initial: AuthenticationMachineState.CHECKING_IF_LOGGED_IN,
    context: {
      userProfile: {}
    },
    states: {
      [AuthenticationMachineState.CHECKING_IF_LOGGED_IN]: {
        invoke: {
          src: AuthenticationMachineState.CHECK_IF_LOGGED_IN,
          onError: {
            target: AuthenticationMachineState.LOGGED_OUT,
          },
        },
        on: {
          [AuthenticationMachineEvent.REPORT_IS_LOGGED_IN]: {
            target: AuthenticationMachineState.LOGGED_IN,
            actions: 'assignUserDetailsToContext',
          },
          [AuthenticationMachineEvent.REPORT_IS_LOGGED_OUT]: AuthenticationMachineState.LOGGED_OUT,
        },
      },
      [AuthenticationMachineState.LOGGED_IN]: {
        on: {
          [AuthenticationMachineEvent.LOG_OUT]: {
            target: AuthenticationMachineState.LOGGED_OUT,
          },
        },
      },
      [AuthenticationMachineState.LOGGED_OUT]: {
        entry: ['navigateToAuthPage', 'clearUserDetailsFromContext'],
        on: {
          [AuthenticationMachineEvent.LOG_IN]: {
            target: AuthenticationMachineState.CHECKING_IF_LOGGED_IN,
          },
        },
      },
    },
  },
  {
    services: {
      CHECK_IF_LOGGED_IN: () => async (
        send,
      ) => {
        const cookieGroup = await checkNaniLinkLogin();
        if (cookieGroup) {
          const { status, data: profile } = await getMe();
          let isLogin = false;
          let data = {};
          if (status !== 'success') {
            logoutNaniOneClass();
            isLogin = false;
          } else {
            isLogin = true;
            data = {
              ...profile
            };
          }
          send({
            type: 'REPORT_IS_LOGGED_IN',
            isLogin,
            data
          });
        } else {
          send({
            type: 'REPORT_IS_LOGGED_OUT',
          });
        }
      },
    },
    actions: {
      navigateToAuthPage: (context) => {
        const { navigatorAuth, } = context;
        logoutNaniOneClass();
        navigatorAuth();
      },
      assignUserDetailsToContext: assign((context, event) => {
        const { navigatorAuth } = context;
        if (
          event.type !== AuthenticationMachineEvent.REPORT_IS_LOGGED_IN &&
          event.type !== AuthenticationMachineEvent.LOG_IN
        ) {
          return {};
        }
        if (event.isLogin) {
          return {
            userProfile: event.data,
          };
        } else {
          navigatorAuth();
        }
      }),
      clearUserDetailsFromContext: assign({
        userProfile: undefined,
      }),
    },
  },
);

export default authenticationMachine;
