import styled from 'styled-components';
import { get } from '../../utils/theme';
import { Picker } from 'antd-mobile';

export const StyledDatePicker = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 16px;
  border-radius: 20px;
  position: relative;
  user-select: none;

  .row {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    margin-top: 8px;
  }
  .row-middle {
    align-items: center;
  }
  .column {
    width:calc(100% / 7);
    color: #454B5C;
    text-align: center;
  }

  .cell {
    position: relative;
    /* width: 40px; */
    height: 32px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .disabled {
    color: #D6DAE0;
  }
  
  .number{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  @media screen and (min-width: ${get('breakpoints.1')}) {
    display: block;
    width: 328px;
    padding: 20px;
    background-color: #fff;
  }
`;

export const UiPanelHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 8px 0 0;
    padding: 4px 0;
    flex: 0 0 56px;

    .todayBtn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      font-size: 18px;
    }

    @media screen and (min-width: ${get('breakpoints.1')}) {
      flex-direction: row;
      margin: 0 12px;
      align-items: center;
      flex: 1;
    }
`;

export const UiLeftTool = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .modeText{
    color: #242C3F;
    cursor: pointer;
  }

  @media screen and (min-width: ${get('breakpoints.1')}) {
    width: auto;
  }
`;

export const UiModeBtn = styled.div`
  display: none;
  color: #242C3F;
  cursor: pointer;

  @media screen and (min-width: ${get('breakpoints.1')}) {
    display: block;
  }
`;

export const UiDateSelectBtn = styled.div`
  height: 32px;
  line-height: 32px;
  @media screen and (min-width: ${get('breakpoints.1')}) {
    display: none;
  }
`;

export const UiPickerController = styled.div`
  display: none;
  
  .item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: #A1A4B1;
    cursor: pointer;
    
    &+.item{
      margin-left: 8px;
    }
  }
  @media screen and (min-width: ${get('breakpoints.1')}) {
    display: flex;
    margin-left: 8px;
  }
`;

export const UiPicker = styled.div`
  width: calc(100% - 62px);
  @media screen and (min-width: ${get('breakpoints.1')}) {
    width: 100%;  
  }
`;

export const UiPickerHead = styled.div`
  .days {
    font-weight: 400;
    margin: 4px 0;
    
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-size: 12px;
      color: #91949E;
    }
  }
`;

export const UiPickerBody = styled.div`
  width: 100%;
  margin-top: 8px;

  .column {
    font-size: 14px;
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);

  }
  .selected {
    .number {
      background-color: ${get('colors.brand.brand02')};
      color: #fff;
    }
    &:hover{
      .number{
        opacity:.8;
      } 
    }
  }
  .today {
    .number{
      background-color: ${get('colors.brand.brand01')};
      color: #fff;
    }
    &:hover{
      .number{
        opacity:.8;
      } 
    }
  }
`;

export const UiDayPicker = styled.div`
  width: 100%;
`;

export const UiMonthPicker = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px 0;
  margin: 16px 0;

  .monthCell{
    display: flex;
    align-items: center;
    width: 64px;
    height: 64px;
    margin: 2px;
    &.current{
      .number{
        color: #fff;
        background-color: ${get('colors.brand.brand01')};
      }
    }
  }

  .number{
    width: 100px;
    text-align: center;
    border-radius: 4px;
    padding: 8px 0;
    line-height: 16px;
    cursor: pointer;
    color: #595959;
    transition: .3s;

    &:hover {
      background-color: ${get('colors.state.hover.row')};
    }
  }
`;

export const UiYearPicker = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px 0;
  margin: 16px 0;

  .yearCell{
    display: flex;
    align-items: center;
    width: 64px;
    padding: 16px 0;
    margin: 2px;
    &.current{
      .number{
        color: #fff;
        background-color: ${get('colors.brand.brand01')};
      }
    }
  }
  .number{
    width: 100px;
    text-align: center;
    border-radius: 4px;
    padding: 8px 0;
    line-height: 16px;
    cursor: pointer;
    color: #595959;
    transition: .3s;
    
    &:hover {
      background-color: ${get('colors.state.hover.row')};
    }
  }
`;

export const SelectPicker = styled(Picker)`
  border-radius:10px 10px 0 0;
  .am-picker-popup-header{
    padding-top: 8px;
  }
  .am-picker-popup-header-right {
    cursor: pointer;
    color: ${get('colors.scale.board1.$board1_2')};
  }
  .am-picker-popup-header-left{
    cursor: pointer;
    color: #454B5C;
  }
  .am-picker-popup-title{
    padding: 12px 15px;
  }
`;

export const UiMobileTool = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 4px;

  @media screen and (min-width: ${get('breakpoints.1')}) {
    display: none;
  }
`;

export const CollapseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  color: #91949E;
  transition: .3s;
  &.dayMode {
    transform: rotate(180deg);
  }
`;

