import styled from 'styled-components';

export const StyledDashBoardLayout = styled.div.attrs(props => ({
  className: props.className
}))`
  display: grid;
  grid-template-areas: 'leftSide header' 'leftSide main';
  grid-template-rows: auto 1fr;
  grid-template-columns: 3.5fr 6.5fr;
  height: 100vh;
  overflow:hidden;

  > .leftSidebar {
    display: flex;
    flex-direction: column;
    grid-area: leftSide;
    background: #fafafc;
    padding:30px 48px ;

    > .logo {
      font-size: 0;
      margin-bottom: 30px;

      > a {
        object-fit:contain;
        cursor: pointer;

        > img {
          height: 40px;
        }
      }
    }

    > .greetings {
      line-height: 36px;
      font-size: ${props => props.theme.fontSizes[5]};
      color:${props => props.theme.colors.text.text_10};
      padding: 14px 0
    }

    > .dashSkeleton {
      margin: 16px 0;
    }

    > .main {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      flex: 1;

      > .subTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: ${props => props.theme.space[9]};

        > span {
          line-height: 32px;
          font-size: ${props => props.theme.fontSizes[3]};
          color:${props => props.theme.colors.text.text_07}
        }

        > a {
          line-height: 24px;
          font-size: ${props => props.theme.fontSizes[4]};
          color:${props => props.theme.colors.text.text_06}
        }
      }
    }
  }
    
  > .header {
    grid-area: header;
  }

  > .main {
    grid-area: main;
    padding:14px 48px 30px;
    height: 100%;
    overflow-x: hidden;
  }

`;