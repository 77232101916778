import styled from 'styled-components';

export const GroupTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ active }) => active ? '#121232' : '#F2F4F8'};
  color: ${({ active }) => active ? '#fff' : '#121232'};;
  font-size: 11px;
  padding: 4px 16px;
  border-radius: 12px;

  & + & {
    margin-left: 12px;
  }
`;

export const StyledGroupInformation = styled.div`
  display: grid;
  grid-template-areas: 'header' 'content';
  grid-template-rows: auto 1fr;
  
  > .header {
    grid-area: header;
    .groupName{
      color: #242C3F;
      font-size: 32px;
    }

    .groupInfo {
      padding: 14px 0 24px;
      
      .orgName{
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 16px;
        font-size: 18px;
        color: #454B5C;
      } 

      > .groupTag {
        margin-right: ${props => props.theme.space[2]};
      }
    }

    .controls {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      background-color: #EC7963;
      color: #fff;
    }
  }

  > .content {
    grid-area: content;
    display: flex;

    .box {
      height: 464px;
      padding: 32px;
      border-radius: 20px;
      background: #FAFAFC;
      display: flex;
      flex-direction: column;
    }

    > * + * {
      margin-left: ${props => props.theme.space[8]};
    }

  }
`;