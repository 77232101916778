import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import defaultTheme from './theme';
import GlobalStyle from './globalStyle';

// const Context = createContext(defaultTheme);

type Props = {
  children: React.ReactNode;
  theme: typeof defaultTheme;
}

export function ThemeProvider({ children, theme }: Props) {
  return (
    <StyledThemeProvider theme={{ ...defaultTheme, ...theme }}>
      <Normalize />
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
}