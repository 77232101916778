import { NOTIFICATION_TYPE } from 'constants/index';

const getSubjectName = subjects => subjects.map((subject) => subject.name).join(',');

export const useNotificationsFactory = () => {
    const createNotification = (type, info) => {
      const { hostNickname: host, subjects, sessionName, missionTitle } = info;
      const subject = subjects && getSubjectName(subjects);
      switch (type) {
        case NOTIFICATION_TYPE.SESSION:
          return {
            message: `${host}創建了一堂課程 : ${subject}  ${sessionName}`
          };
        case NOTIFICATION_TYPE.MISSION:
          return {
            message: `${host}新增了作業 : ${missionTitle}`
          };
        default:
          return {
            message: '無'
          };
      }
    };
    return {
      createNotification
    };
};