import 'antd/dist/antd.css';
import 'antd-mobile/dist/antd-mobile.css';
import colors from './colors';

export const fontStack = (fonts: string[]): string => {
  return fonts.map(font => (font.includes(' ') ? `"${font}"` : font)).join(', ');
};

const breakpoints = ['544px', '768px', '1012px', '1280px'];


const fonts = {
  normal: fontStack([
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Helvetica',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
  ]),
};

const fontWeights = {
  light: 300,
  normal: 400,
  semibold: 500,
  bold: 600,
};

const fontSizes = [
  '0',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '40px',
  '48px',
];

const space = [
  '0',
  '4px',
  '8px',
  '12px',
  '16px',
  '20px',
  '24px',
  '28px',
  '32px',
  '36px',
  '40px',
  '44px',
  '48px'
];

const radii = [
  '0',
  '4px',
  '8px',
  '12px',
  '16px',
  '20px',
  '100px',
  '50%',
  '100%'
];

const theme = {
  breakpoints,
  fonts,
  fontSizes,
  space,
  radii,
  fontWeights,
  colors,
};

export default theme;
