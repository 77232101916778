import React, { createContext, useContext, memo } from 'react';
import { useMachine } from '@xstate/react';
import { useHistory } from 'react-router-dom';
import authenticationMachine, { AuthenticationMachineEvent } from 'machines/authMachine';
import { sendData as loginApi } from 'services/oneClub';
import PropTypes from 'prop-types';

const { machine } = authenticationMachine;

const StateContext = createContext();
const DispatchContext = createContext(() => {});

const AuthProvider = memo(({ children }) => {
  const history = useHistory();
  const [state, send] = useMachine(machine, {
    context: {
      navigatorAuth: () => history.push('/login')
    }
  });
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={send}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}, []);

const useAuthState = () => useContext(StateContext);

const useAuth = () => {
  const dispatch = useContext(DispatchContext);

  const login = async payload => {
    const { account, password } = payload;
    const result = await loginApi(account, password);
    if (result.code !== 'SUCCESS') {
      return {
        isSuccess: false,
        errorMessage: result.message
      };
    }

    dispatch(AuthenticationMachineEvent.LOG_IN);

    return {
      isSuccess: true,
      errorMessage: ''
    };
  };
  const logout = () => dispatch(AuthenticationMachineEvent.LOG_OUT);

  return {
    login,
    logout
  };
};


export default AuthProvider;
export { useAuthState, useAuth };

AuthProvider.propTypes = {
  children: PropTypes.node,
};
