import React from 'react';
import { GroupNews } from '@oneclass/ui-components';
import { StyledGroupNewsTable } from './GroupNewsTable.style';

const ICONS_TYPE = {
  class: 'class',
  mission: 'homework',
  session: 'book',
  comments: 'comments',
  album: 'album',
  school: 'school'
};

export const GroupNewsTable = ({ className, data }) => {
  return (
    <StyledGroupNewsTable className={className} data-testid="GroupNewsTable">
      <div className="subtitle">班級動態</div>
      <GroupNews>
        {
          data.map((item, index) => (
            <GroupNews.Item
              key={index}
              type={ICONS_TYPE[item.eventCategory]}
              text={item.message}
              time={item.createdAt}
              // href={item.href}
            />
          ))
        }
      </GroupNews>
    </StyledGroupNewsTable>
  );
};