import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { WeekTimeTable } from '@oneclass/ui-components';
import { DatePicker, Icon } from '@oneclass/onedesign';
import { StyledPersonalTimetablePage } from './PersonalTimetablePage.style';

const data = [
  [
    { contents: ['英文', '三年甲班'], startTime: new Date().getTime() - 100000000, endTime: new Date().getTime() - 10000000 },
    { contents: ['國語國語國語國語國語國語', '一年一班'], startTime: new Date().getTime() + 5000, endTime: new Date().getTime() + 1000000 },
    { contents: ['數學', '三年甲班'], startTime: new Date().getTime() + 1000000, endTime: new Date().getTime() + 100000000 }
  ],
  [
    { contents: ['英文', '三年甲班'], startTime: new Date().getTime() - 100000000, endTime: new Date().getTime() - 10000000 },
    { contents: ['國語國語國語國語國語國語', '一年一班'], startTime: new Date().getTime() + 5000, endTime: new Date().getTime() + 1000000 },
    { contents: ['數學', '三年甲班'], startTime: new Date().getTime() + 1000000, endTime: new Date().getTime() + 100000000 }
  ],
  [
    { contents: ['英文', '三年甲班'], startTime: new Date().getTime() - 100000000, endTime: new Date().getTime() - 10000000 },
    { contents: ['國語國語國語國語國語國語', '一年一班'], startTime: new Date().getTime() + 5000, endTime: new Date().getTime() + 1000000 },
    { contents: ['數學', '三年甲班'], startTime: new Date().getTime() + 1000000, endTime: new Date().getTime() + 100000000 }
  ]
];

export const PersonalTimetablePage = ({ className }) => {
  const [dateTime, setDateTime] = useState(new Date().getTime());

  const changeHandler = (timestamp) => {
    setDateTime(timestamp);
  };

  return (
    <StyledPersonalTimetablePage className={className} data-testid="PersonalTimetablePage">
      <div className="head">
        <Link to="/">
          <div className="backBtn">
            <Icon name='ChevronLeftOutline' size='xl' />
          </div>
        </Link>
        <div className="title">我的課程</div>
      </div>
      <div className="content">
        <div className="weekTableBlock">
          <WeekTimeTable
            weekData={data}
            onChange={changeHandler}
            dateTime={dateTime} />
        </div>
        <div className="sideBlock">
          <div className="dateBlock">
            <DatePicker activeDate={dateTime} onChange={changeHandler} />
          </div>
          <div className="noteBlock"></div>
        </div>

      </div>
    </StyledPersonalTimetablePage>
  );
};