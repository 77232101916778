import styled from 'styled-components';

export const StyledPersonalTimetablePage = styled.div`
    background-color: ${props => props.theme.colors.ui.ui_03};
    height: 100vh;
    padding: 48px 40px 48px;

    > .head{
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      
      .title{
        font-size: 20px;
      }

      .backBtn{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        color: ${props => props.theme.colors.ui.ui_05}
      }
    }
    
    > .content {
      display: flex; 
      width: 100%;
      height: 100%;
      height: calc(100% - 48px - 16px);
    }
    
    .weekTableBlock {
      flex: 1 1 auto;
      min-width: 0;
      margin: 0 12px;
      height: 100%;
    }
    .sideBlock{
      display: flex;
      flex-direction: column;
      height: 100%;
      flex-basis: 328px;
      margin: 0 12px;
    }
    .dateBlock {
      margin: 0 0 12px 0;
    }
    .noteBlock{
      width: 328px;
      height: 100%;
      position: relative;
      background-color: #fff;
      border-radius: 20px;
      margin: 12px 0 0;
    }
    
`;