import React from 'react';
import styled from 'styled-components';
import { Checkbox, CheckboxProps } from 'antd';
// import { CheckboxProps } from './Checkbox';

export const StyledCheckbox: React.FC<CheckboxProps> = styled(Checkbox)`

  .ant-checkbox-inner {
    border: 1px solid #E4E7EC;
    background-color: #fff;
    border-radius: 2px;
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
        background-color: #F2F4F8;
        border-color: #F2F4F8 !important;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #121232;
      border-color: #121232 !important;
      color: #fff;

      &:after {
        border-color: #fff;
      }
    }
  }

  .ant-checkbox-disabled.ant-checkbox-checked{
    .ant-checkbox-inner {
      background-color: #F2F4F8;
      border-color: #F2F4F8 !important;
      color: #fff;

      &:after {
        border-color: #fff;
      }
    }
  }

  .ant-checkbox-indeterminate{
    .ant-checkbox-inner {
        background-color: #121232;
        border-color: #121232;
        color: #fff;

        &:after {
          width: 8px;
          height: 2px;
          background-color: #fff;
        }
      }
  }
`;