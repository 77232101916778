import React, { useEffect, useMemo } from 'react';
import { SessionCard } from '@oneclass/ui-components';
import { useMachine } from '@xstate/react';
import { getSessions } from 'api/sessions';
import fetchDataMachine, { FETCH_DATA_EVENT } from 'machines/fetchDataMachine';
import { Skeleton } from '@oneclass/onedesign';
import { format } from 'date-fns';
import Book from 'assets/images/book.png';
import { StyledSessionGroup } from './SessionGroup.style';

const nowTime = new Date().getTime();
const todayStartAt = new Date(new Date().toLocaleDateString()).getTime();
const todayEndAt = todayStartAt + (24 * 60 * 60 * 1000) - 1;

export const SessionGroup = ({ className }) => {

  const [sessionsContext, send] = useMachine(fetchDataMachine, {
    services: {
      fetchData: (_context, event) => {
        const payload = {
          ...event
        };
        delete payload.type;
        return getSessions(payload);
      },
    },
  });
  const { sessions } = sessionsContext.context.data || {};
  const isLoading = sessionsContext.context.isLoading;

  const sessionsData = useMemo(() => {
    return sessions?.map(item => {
      let isActive = true;
      if (nowTime > item.end) isActive = false;
      const startAtText = format(item.startAt, 'HH:mm');
      const endAtText = format(item.endAt, 'HH:mm');
      const sessionInterval = `${startAtText} - ${endAtText}`;
      return {
        ...item,
        isActive,
        sessionInterval
      };
    }) || [];
  }, [sessions]);

  useEffect(() => {
    send(FETCH_DATA_EVENT.FETCH, {
      endAt: todayEndAt,
      startAt: todayStartAt
    });
  }, []);

  return (
    <StyledSessionGroup className={className} data-testid="SessionGroup">
      {
        isLoading ?
        <>
           <Skeleton variant="block" height="120px"/>
           <Skeleton variant="block" height="120px"/>
           <Skeleton variant="block" height="120px"/>
        </> :
        sessionsData.map((item, index) => {
          return (
            <SessionCard
              key={index}
              title={item.name}
              groupName={item.groupName}
              hostName={item.hostName}
              isActive={item.isActive}
              sessionInterval={item.sessionInterval}
              defaultImg={Book}
            />
          );
        })
      }
    </StyledSessionGroup>
  );
};