import styled from 'styled-components';

export const StyledGroupTimeTable = styled.div.attrs(props => ({
  className: props.className
}))`
   .dateSelect {
     display: flex;
     align-items: center;
     color: #242C3F;
     font-weight: 400;
     font-size: 24px;
   }

   .controlsButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      color: #8B90A0;
      background-color: #FFF;
      border-radius: 8px;
      cursor: pointer;
      user-select: none;
   }

   .checkAll{
     color: #8B90A0;
     font-size: 14px;
     user-select: none;
     cursor: pointer;
   }
`;