import styled from 'styled-components';

export const StyledGroupNewsTable = styled.div.attrs(props => ({
  className: props.className
}))`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  > .subtitle {
    color: #242c3f;
    font-size: ${(props) => props.theme.fontSizes[5]};
    margin-bottom: ${(props) => props.theme.space[3]};
    line-height: 30px;
  }
`;