import React, { useState } from 'react';
import { GroupInformation, GroupSelect } from 'containers';
import { StyledDashBoardPage } from './DashBoardPage.style';

export const DashBoardPage = ({ className }) => {
  const [targetGroup, setTargetGroup] = useState(null);
  const onGroupChange = item => {
    setTargetGroup(item);
  };

  return (
    <StyledDashBoardPage className={className}>
      <GroupSelect onChange={onGroupChange}/>
      <GroupInformation targetGroup={targetGroup}/>
    </StyledDashBoardPage>
  );
};