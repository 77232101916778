import { Api } from './index';

/**
 * 獲取個人班級
 */
export const getGroups = async params => {
  const response = await Api.get('/groups', params);
  return response;
};

/**
 * 獲取個人課表
 */
 export const getGroupSessions = async (groupId, params) => {
  const response = await Api.get(`/groups/${groupId}/sessions`, params);
  return response;
};

