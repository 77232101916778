import { assign, createMachine } from 'xstate';

export const FETCH_DATA_STATE = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
  DONE: 'DONE'
};

export const FETCH_DATA_EVENT = {
  FETCH: 'FETCH',
  CANCEL: 'CANCEL',
  RECEIVE_DATA: 'RECEIVE_DATA'
};

const fetchDataMachine = createMachine(
  {
    id: 'fetchData',
    initial: FETCH_DATA_STATE.IDLE,
    states: {
      [FETCH_DATA_STATE.IDLE]: {
        on: {
          FETCH: {
            target: FETCH_DATA_STATE.FETCHING,
          },
        },
        initial: 'noError',
        states: {
          noError: {
            entry: ['clearErrorMessage'],
          },
          errored: {},
        },
      },
      [FETCH_DATA_STATE.FETCHING]: {
        entry: ['assignFetchingData'],
        invoke: {
          src: 'fetchData',
          onDone: {
            actions: 'assignDataToContext',
            target: FETCH_DATA_STATE.DONE
          },
          onError: {
            target: 'IDLE.errored',
            actions: 'assignErrorToContext',
          },
        },
      },
      [FETCH_DATA_STATE.DONE]: {
        on: {
          [FETCH_DATA_EVENT.FETCH]: {
            target: FETCH_DATA_STATE.FETCHING,
          },
          [FETCH_DATA_EVENT.CANCEL]: {
            target: FETCH_DATA_STATE.IDLE,
          },
          [FETCH_DATA_EVENT.RECEIVE_DATA]: {
            target: FETCH_DATA_STATE.IDLE,
            actions: 'assignDataToContext',
          },
        },
      }
    },
  },
  {
    actions: {
      assignDataToContext: assign((context, event) => {
        if (event.type === FETCH_DATA_EVENT.RECEIVE_DATA) return {};
        return {
          data: event.data.data,
          isLoading: false,
        };
      }),
      assignFetchingData: assign({
        isLoading: true,
      }),
      clearErrorMessage: assign({
        errorMessage: undefined,
        isLoading: false,
      }),
      assignErrorToContext: assign((context, event) => {
        return {
          errorMessage: event.data?.message || 'An unknown error occurred',
          isLoading: false,
        };
      }),
    },
  },
);

export default fetchDataMachine;
