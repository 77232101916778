import React, { useState, useEffect } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { useAuthState } from 'providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import { AuthenticationMachineState } from 'machines/authMachine';
import { StyledLoginPage } from './LoginPage.style';

export const LoginPage = () => {
  const { login } = useAuth();
  const authState = useAuthState();
  const history = useHistory();
  const [state, setState] = useState({
    account: {
      value: '',
      message: ''
    },
    password: {
      value: '',
      message: ''
    }
  });

  const onChange = (e, key) => {

    const value = e.target.value;
    setState({
      ...state,
      [key]: {
        value,
        message: ''
      }
    });
  };

  const submitHandle = async () => {
    const { account, password } = state;
    const payload = {
      account: account.value,
      password: password.value
    };
    const isSuccess = await login(payload);
    console.log('isSuccess', isSuccess);
  };

  useEffect(() => {
    if (authState.value === AuthenticationMachineState.LOGGED_IN) {
      history.push('/');
    }
  }, [authState.value]);

  return (
    <StyledLoginPage>
      oneLink登入
      <div>
        帳號
        <input type="text" onChange={e => onChange(e, 'account')} value={state.account.value}/>
      </div>
      <div>
        密碼
        <input type="password" onChange={e => onChange(e, 'password')} value={state.password.value}/>
      </div>
      <button onClick={submitHandle}>送出</button>
    </StyledLoginPage>
  );
};