import styled from 'styled-components';

export const StyledHeader = styled.div.attrs(props => ({
  className: props.className
}))`
  display:flex;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  padding: 28px 32px 24px;
  align-items:center;
  justify-content:flex-end;
  & > .av {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    & + * {
      margin-left: 24px;
    }
  }
`;