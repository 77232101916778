import styled from 'styled-components';

export const StyledGroupSelect = styled.div.attrs(props => ({
  className: props.className
}))`
  width: 100%;

  > .text {
    font-size: 24px;
    font-weight: 400;
    color: #242C3F;
    padding-bottom: 22px;
  }

  .noDataBar {
    margin-bottom: 72px;
    display: flex;
    align-items: center;
    
    & > * {
      margin-right: ${props => props.theme.space[3]};
    }
  }
`;