import {
  LoginPage,
  DashBoardPage,
  PersonalTimetablePage
} from 'views';

import {
  DashBoardLayout,
} from 'layouts';

const routes = [
  {
    path: '/login',
    component: LoginPage,
    exact: true,
  },
  {
    path: '/',
    component: DashBoardLayout,
    exact: true,
    routes: [
      {
        path: '/',
        component: DashBoardPage,
        name: 'DashBroad',
        exact: true,
      }
    ],
  },
  {
    path: '/personalTimetable',
    component: PersonalTimetablePage,
    exact: true,
  },
];
export default routes;
