import styled from 'styled-components';

export const StyledLoginPage = styled.div.attrs(props => ({
  className: props.className
}))`
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  background-color: #999;
  width: 100%;
  height: 100vh;
  
  & > div {
    margin: 10px;
    color:#f00;
  }

  & > button {
    cursor:pointer;
  }
`;