import React, { useState, useEffect } from 'react';
import { Swiper, GroupAvatar } from '@oneclass/ui-components';
import { useMachine } from '@xstate/react';
import { getGroups } from 'api/groups';
import fetchDataMachine, { FETCH_DATA_EVENT } from 'machines/fetchDataMachine';
import { Icon, Skeleton } from '@oneclass/onedesign';
import { StyledGroupSelect } from './GroupSelect.style';

const randomImage = (index) => {
  const res = require.context('assets/images/groups', false, /\.(png|jpe?g|svg)$/);
  const images = res.keys().map((item) => {
    return res(item).default;
  });
  return images[index % 18];
};


export const GroupSelect = ({ className, onChange }) => {
  const [activeGroup, setActiveGroup] = useState(0);

  const onActiveHandle = (activeIndex, target) => {
    setActiveGroup(activeIndex);
    onChange && onChange(target);
  };

  const [groupsContext, send] = useMachine(fetchDataMachine, {
    services: {
      fetchData: (_context, event) => {
        const payload = {
          ...event
        };
        delete payload.type;
        return getGroups(payload);
      },
    },
  });

  const groupsData = groupsContext.context?.data || [];
  const isLoading = groupsContext.context.isLoading;

  useEffect(() => {
    send(FETCH_DATA_EVENT.FETCH);
  }, []);

  useEffect(() => {
    if (groupsData.length === 0) return;
    onChange && onChange(groupsData[0]);
  }, [groupsData]);

  return (
    <StyledGroupSelect className={className} data-testid="GroupSelect">
      <div className="text">{isLoading ? <Skeleton width="200px"/> : '我的班級'}</div>
      { groupsData.length >= 1 ?
      <Swiper mb={7}>
        <GroupAvatar className="inline">
          <Icon name="PlusOutline"/>
        </GroupAvatar>
        {
          groupsData.map((item, index) => {
            return (
              <GroupAvatar
                key={index}
                src={item.thumbnailUrl || randomImage(index)}
                label={item.name}
                alt={item.name}
                pr={3}
                active={activeGroup === index}
                onClick={() => onActiveHandle(index, item)}
              />
            );
          })
        }
      </Swiper> :
      <div className="noDataBar">
        {
          isLoading ?
          <>
            <Skeleton variant="image" />
            <Skeleton variant="image" />
            <Skeleton variant="image" />
            <Skeleton variant="image" />
            <Skeleton variant="image" />
            <Skeleton variant="image" />
            <Skeleton variant="image" />
          </> :
           <GroupAvatar>
              <Icon name="PlusOutline"/>
           </GroupAvatar>
        }
      </div>}
    </StyledGroupSelect>
  );
};